import get from "lodash.get";

export function getShiftHandoverValidationErrors(validationResult) {
  let errors = {};

  if (get(validationResult, "formData.handoverBase.endTime.$invalid")) {
    errors["formData.handoverbase.endTime"] = "Shift's end time is required";
  }

  if (
    get(validationResult, "formData.confirmation.handoverSignature.$invalid")
  ) {
    errors["formData.confirmation.handoverSignature."] =
      "Handover signature is required";
  }

  if (get(validationResult, "formData.psmSummary.data.$invalid")) {
    errors["formData.psmSummary.data"] = "PSM summary data is missing";
  }

  if (get(validationResult, "formData.pscManualChangeSummary.data.$invalid")) {
    errors["formData.pscManualChangeSummary.data"] =
      "PSC manual change summary data is missing";
  }

  if (get(validationResult, "formData.preEncodedTicketSummary.data.$invalid")) {
    errors["formData.preEncodedTicketSummary.data"] =
      "Pre encoded ticket summary data is missing";
  }

  if (get(validationResult, "formData.gtmCoinFloatSummary.data.$invalid")) {
    errors["formData.gtmCoinFloatSummary.data"] =
      "GTM coin float summary data is missing";
  }

  if (get(validationResult, "formData.gtmCashBoxSummary.data.$invalid")) {
    errors["formData.gtmCashBoxSummary.data"] =
      "GTM cash box summary data is missing";
  }

  if (get(validationResult, "formData.sccfSummary.data.$invalid")) {
    errors["formData.sccfSummary.data"] =
      "Station cash collection summary data is missing";
  }

  if (get(validationResult, "formData.stationTransferSummary.data.$invalid")) {
    errors["formData.stationTransferSummary.data"] =
      "Station transfer summary data is missing";
  }

  if (get(validationResult, "formData.smartCardSummary.data.$invalid")) {
    errors["formData.smartCardSummary.data"] =
      "Smart card summary data is missing";
  }

  if (get(validationResult, "formData.dutySMKeySummary.data.$invalid")) {
    errors["formData.dutySMKeySummary.data"] = "Duty SM key data is missing";
  }

  if (get(validationResult, "formData.dutySMItemSummary.data.$invalid")) {
    errors["formData.dutySMItemSummary.data"] =
      "SM Duty Radio Set and Duty Phones data is missing";
  }

  if (get(validationResult, "formData.faultSummary.data.$invalid")) {
    errors["formData.faultSummary.data"] =
      "Outstanding fault summary data is missing";
  }

  if (get(validationResult, "formData.faultOccurrenceSummary.data.$invalid")) {
    errors["formData.faultOccurrenceSummary.data"] =
      "Fault occurrence summary data is missing";
  }

  if (get(validationResult, "formData.handoverBase.takeoverUserId.$invalid")) {
    errors["formData.handoverBase.takeoverUserId"] =
      "Takeover user is required for full handover";
  }

  if (
    get(validationResult, "formData.psmSummary.discrepancyReports.$invalid")
  ) {
    errors["formData.psmSummary.discrepancyReports"] =
      "Discrepancy report is required for unaligned PSM summary data";
  }

  if (
    get(
      validationResult,
      "formData.pscManualChangeSummary.discrepancyReports.$invalid"
    )
  ) {
    errors["formData.pscManualChangeSummary.discrepancyReports"] =
      "Discrepancy report is required for unaligned PSC manual change summary data";
  }

  if (
    get(
      validationResult,
      "formData.preEncodedTicketSummary.discrepancyReports.$invalid"
    )
  ) {
    errors["formData.preEncodedTicketSummary.discrepancyReports"] =
      "Discrepancy report is required for unaligned Pre encoded ticket data";
  }

  if (
    get(
      validationResult,
      "formData.afcCashBagSummary.discrepancyReports.$invalid"
    )
  ) {
    errors["formData.afcCashBagSummary.discrepancyReports"] =
      "Discrepancy report is required for unaligned AFC cash bag summary data";
  }

  if (
    get(
      validationResult,
      "formData.gtmCoinFloatSummary.discrepancyReports.$invalid"
    )
  ) {
    errors["formData.gtmCoinFloatSummary.discrepancyReports"] =
      "Discrepancy report is required for unaligned GTM coin float summary data";
  }

  if (
    get(
      validationResult,
      "formData.gtmCashBoxSummary.discrepancyReports.$invalid"
    )
  ) {
    errors["formData.gtmCashBoxSummary.discrepancyReports"] =
      "Discrepancy report is required for unaligned GTM cash box summary data";
  }

  if (
    get(validationResult, "formData.sccfSummary.discrepancyReports.$invalid")
  ) {
    errors["formData.sccfSummary.discrepancyReports"] =
      "Discrepancy report is required for unaligned Station cash collection summary data";
  }

  if (
    get(
      validationResult,
      "formData.stationTransferSummary.discrepancyReports.$invalid"
    )
  ) {
    errors["formData.stationTransferSummary.discrepancyReports"] =
      "Discrepancy report is required for unaligned Station transfer summary data";
  }

  if (get(validationResult, "formData.smartCardSummary.remarks.$invalid")) {
    errors["formData.smartCardSummary.remarks"] =
      "Remarks is required for unaligned smart card summary data (Please enter at least 10 characters)";
  }

  if (get(validationResult, "formData.fitnessConfirmation.remarks.$invalid")) {
    errors["formData.fitnessConfirmation.remarks"] =
      "Remarks is required for unaligned health declaration data (Please enter at least 10 characters)";
  }

  if (get(validationResult, "formData.afcKeySummary.remarks.$invalid")) {
    errors["formData.afcKeySummary.remarks"] =
      "Remarks is required for unaligned AFC spared store key data (Please enter at least 10 characters)";
  }

  if (get(validationResult, "formData.dutySMKeySummary.remarks.$invalid")) {
    errors["formData.dutySMKeySummary.remarks"] =
      "Remarks is required for unaligned Duty SM key data (Please enter at least 10 characters)";
  }

  if (get(validationResult, "formData.dutySMItemSummary.remarks.$invalid")) {
    errors["formData.dutySMItemSummary.remarks"] =
      "Remarks is required for unaligned SM Duty Radio Set and Duty Phones data (Please enter at least 10 characters)";
  }

  if (get(validationResult, "formData.faultSummary.remarks.$invalid")) {
    errors["formData.faultSummary.remarks"] =
      "Remarks is required for unaligned outstanding fault data (Please enter at least 10 characters)";
  }

  if (
    get(validationResult, "formData.faultOccurrenceSummary.remarks.$invalid")
  ) {
    errors["formData.faultOccurrenceSummary.remarks"] =
      "Remarks is required for unaligned fault occurrence data (Please enter at least 10 characters)";
  }

  if (get(validationResult, "formData.remarks.$invalid")) {
    errors["formData.remarks"] = "Remarks must be at least 10 characters";
  }

  console.log(errors);

  return {
    message: errors,
  };
}
